import React, { useState, useEffect } from 'react';
import { Header as HeaderBox, Navbar, Nav, Icon, Dropdown } from 'rsuite';
import styles from './Header.module.scss';

const Header = () => {
    // Получаем значение из локального хранилища или по умолчанию 'Confyday'
    const [selectedApp, setSelectedApp] = useState(
        localStorage.getItem('selectedApp') || 'Confyday',
    );

    // Функция для изменения выбранного приложения
    const handleAppChange = (app) => {
        setSelectedApp(app);
        localStorage.setItem('selectedApp', app);
        window.location.reload();
    };

    const logOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('expiresIn');
        window.location.reload();
    };

    return (
        <HeaderBox className={styles.Header}>
            <Navbar className={styles.navbar}>
                <Navbar.Header>
                    <Nav>
                        <Nav.Item href={'/'} className={styles.logo} icon={<Icon icon="home" />}>
                            {selectedApp.toUpperCase()}
                        </Nav.Item>
                    </Nav>
                </Navbar.Header>
                <Navbar.Body>
                    <Nav pullRight>
                        <Dropdown
                            title={selectedApp}
                            activeKey={selectedApp}
                            onSelect={handleAppChange}
                        >
                            <Dropdown.Item eventKey="Confyday">Confyday</Dropdown.Item>
                            <Dropdown.Item eventKey="Wisme">Wisme</Dropdown.Item>
                        </Dropdown>
                        <Nav.Item onClick={logOut} icon={<Icon icon="cog" />}>
                            Log out
                        </Nav.Item>
                    </Nav>
                </Navbar.Body>
            </Navbar>
        </HeaderBox>
    );
};

export default Header;
