import { useEffect, useState } from 'react';
import { Button, Table, Checkbox, Pagination, Modal, Divider } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { API } from '../../helpers/api';
import styles from './styles.module.scss';

const Users = () => {
    const history = useHistory();
    const location = useLocation();

    const [users, setUsers] = useState([]);
    const [userCount, setUserCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [isModalOpen, setModalOpen] = useState(false);
    const [onDeleteUserId, setDeleteUserId] = useState(null);
    const [isOnlyPremium, setIsOnlyPremium] = useState(false);
    const [isOnlyInfinity, setIsOnlyInfinity] = useState(false);

    const initialVisibleColumns = JSON.parse(localStorage.getItem('visibleColumns')) || {
        id: true,
        name: true,
        email: true,
        phone: true,
        createdAt: true,
        actions: true,
        followers: true,
    };

    const [visibleColumns, setVisibleColumns] = useState(initialVisibleColumns);

    const handleColumnToggle = (column) => {
        console.log(column);
        const newVisibleColumns = {
            ...visibleColumns,
            [column]: !visibleColumns[column],
        };
        setVisibleColumns(newVisibleColumns);
        localStorage.setItem('visibleColumns', JSON.stringify(newVisibleColumns));
    };

    useEffect(() => {
        getUsers();
    }, [currentPage, pageSize]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const page = parseInt(queryParams.get('page'), 10) || 1;
        setCurrentPage(page);
    }, [location.search]);

    const getUsers = () => {
        API({
            url: `v1/users?take=${pageSize}&skip=${(currentPage - 1) * pageSize}`,
            method: 'GET',
        }).then((res) => {
            const modifiedUsers = res.data.users.map((user) => ({
                ...user,
                phone: user.phone ? user.code_country + user.phone : '',
            }));

            setUsers(modifiedUsers);
            setUserCount(res.data.count);
        });
    };

    const modalAgreeHandler = () => {
        API({ url: 'v1/users/' + onDeleteUserId, method: 'DELETE' }).then(() => {
            getUsers();
            setModalOpen(false);
        });
    };

    const deleteUsers = (id) => {
        setDeleteUserId(id);
        setModalOpen(true);
    };

    const normalizeDate = (date) => {
        return date.slice(0, 10);
    };

    const toggleOnlyPremium = () => {
        setIsOnlyPremium(!isOnlyPremium);
        getUsers();
    };

    const toggleOnlyInfinity = () => {
        setIsOnlyInfinity(!isOnlyInfinity);
        getUsers();
    };

    const changeIsAuthor = (userId) => {
        console.log('WTF');
        API({
            url: 'v1/users/account/isauthor',
            method: 'POST',
            data: { userId },
        }).then(() => getUsers());
    };

    const changeInfinitySubscribe = (userId) => {
        API({
            url: 'v1/users/account/isinfinity',
            method: 'POST',
            data: { userId },
        }).then(() => getUsers());
    };

    const changeInfinityMiniSubscribe = (userId) => {
        API({
            url: 'v1/users/account/isinfinity/mini',
            method: 'POST',
            data: { userId },
        }).then(() => getUsers());
    };

    const columns = [
        visibleColumns.id ? { title: 'Id', dataIndex: 'id', key: 'id', width: 70 } : {},
        { title: 'Phone Number', dataIndex: 'phone', key: 'phone', width: 150 },
        { title: 'Username', dataIndex: 'nickName', key: 'nickName', width: 200 },
        visibleColumns.email
            ? { title: 'Email', dataIndex: 'email', key: 'email', width: 300 }
            : {},
        {
            title: 'Photo',
            key: 'photo',
            render: (text, record) =>
                record.photoUrl ? (
                    <div
                        className={styles.photo}
                        style={{ backgroundImage: `url(${record.photoUrl})` }}
                    ></div>
                ) : null,
        },
        {
            title: 'Plan',
            key: 'plan',
            render: (text, record) => (
                <div className={record.plan === 'Basic' ? styles.basic : styles.pro}>
                    {record.plan}
                </div>
            ),
            width: 130,
        },
        { title: 'Period', dataIndex: 'subscribe_period', key: 'subscribe_period', width: 100 },
        {
            title: 'Author',
            key: 'is_author',
            render: (text, record) => (
                <Checkbox checked={record.is_author} onChange={() => changeIsAuthor(record.id)} />
            ),
            width: 100,
        },
        {
            title: 'Infinity',
            key: 'is_infinity_subscribe',
            render: (text, record) => (
                <Checkbox
                    checked={record.is_infinity_subscribe}
                    onChange={() => changeInfinitySubscribe(record.id)}
                />
            ),
            width: 100,
        },

        {
            title: 'Infinity Mini',
            key: 'is_infinity_subscribe_mini',
            render: (text, record) => (
                <Checkbox
                    checked={record.is_infinity_subscribe_mini}
                    onChange={() => changeInfinityMiniSubscribe(record.id)}
                />
            ),
            width: 120,
        },
        { title: 'Reports', dataIndex: 'reports_count', key: 'reports_count', width: 100 },
        visibleColumns.followers
            ? {
                  title: 'Followers',
                  dataIndex: 'followers_count',
                  key: 'followers_count',
                  width: 100,
              }
            : {},
        visibleColumns.followers
            ? {
                  title: 'Following',
                  dataIndex: 'following_count',
                  key: 'following_count',
                  width: 100,
              }
            : {},
        {
            title: 'Bio',
            key: 'bio',
            render: (text, record) => <div title={record.bio}>{record.bio}</div>,
            width: 450,
        },
        visibleColumns.createdAt
            ? {
                  title: 'Created',
                  key: 'createdAt',
                  render: (text, record) => <div>{normalizeDate(record.createdAt)}</div>,
                  width: 120,
              }
            : {},
        visibleColumns.actions
            ? {
                  title: 'Action',
                  key: 'action',
                  render: (text, record) => (
                      <Button
                          type="primary"
                          onClick={() => history.push(`/users/${record.id}`)} // Переход на страницу профиля пользователя
                      >
                          Go to Profile
                      </Button>
                  ),
              }
            : {},
        visibleColumns.actions
            ? {
                  title: 'Action',
                  key: 'action',
                  render: (text, record) => (
                      <Button type="primary" danger onClick={() => deleteUsers(record.id)}>
                          Remove
                      </Button>
                  ),
              }
            : {},
    ];

    return (
        <div className={styles.container}>
            <div className={styles.countUser}>
                Total users: <strong>{userCount}</strong>
            </div>
            <Button
                type="primary"
                onClick={() => history.push(`/users/create`)} // Переход на страницу профиля пользователя
            >
                Create new
            </Button>
            <Divider type="vertical" />
            <div className={styles.checkbox}>
                <Checkbox onChange={toggleOnlyPremium} checked={isOnlyPremium}>
                    Only premium
                </Checkbox>
            </div>
            <div className={styles.checkbox}>
                <Checkbox onChange={toggleOnlyInfinity} checked={isOnlyInfinity}>
                    Only infinity
                </Checkbox>
            </div>
            <Divider type="vertical" dashed />

            <div className={styles.columnToggles}>
                <Checkbox checked={visibleColumns.id} onChange={() => handleColumnToggle('id')}>
                    Id
                </Checkbox>
                <Checkbox
                    checked={visibleColumns.email}
                    onChange={() => handleColumnToggle('email')}
                >
                    Email
                </Checkbox>
                <Checkbox
                    checked={visibleColumns.followers}
                    onChange={() => handleColumnToggle('followers')}
                >
                    Followers
                </Checkbox>
                <Checkbox
                    checked={visibleColumns.createdAt}
                    onChange={() => handleColumnToggle('createdAt')}
                >
                    Created At
                </Checkbox>
                <Checkbox
                    checked={visibleColumns.actions}
                    onChange={() => handleColumnToggle('actions')}
                >
                    Actions
                </Checkbox>
            </div>

            <Divider type="vertical" dashed />

            <Table
                columns={columns}
                dataSource={users}
                pagination={false}
                rowKey="id"
                style={{ width: '100%' }}
                scroll={{ x: true }}
                rowClassName={styles.fixedRow}
            />
            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={userCount}
                onChange={(page) => {
                    history.push(`?page=${page}`);
                    setCurrentPage(page);
                }}
                style={{ marginTop: 20, textAlign: 'center' }}
            />
            <Modal
                title="Confirm Deletion"
                visible={isModalOpen}
                onOk={modalAgreeHandler}
                onCancel={() => setModalOpen(false)}
            >
                <p>Are you sure you want to delete this user?</p>
            </Modal>
        </div>
    );
};

export default Users;
